<script>
/*
	import ActionButton from '@/components/buttons/Action'
*/

/*
	<ActionButton
		@click=""
	>Action</ActionButton>
*/
export default {
  props: {}
}
</script>

<template>
  <b-button make-request type="is-primary" rounded @click="$emit('click')">
    <slot />
  </b-button>
</template>
