export const data = () => ({
  toDateComponent: null,
  toDate: null,
  fromDateComponent: null,
  fromDate: null,
  loading: false,
  isDebug: true,
  selectedRow: null,
  radio: 'default',
  staus: '',
  columns: [
    {
      field: 'requestID',
      label: 'ID',
      width: '5%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'name',
      label: 'Name',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'address',
      label: 'Address',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'category',
      label: 'Category',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'date',
      label: 'Date Submitted',
      width: '10%',
      date: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'status',
      label: 'Status',
      width: '10%',
      sortable: false,
      selected: 'Pending',
      searchable: [
        {
          label: 'All',
          filter: ''
        },
        {
          label: 'Approved',
          filter: 'Approved'
        },
        {
          label: 'Pending',
          filter: 'Pending'
        },
        {
          label: 'My Pending',
          filter: 'Pending'
        },
        {
          label: 'Denied',
          filter: 'Denied'
        },
        {
          label: 'Cancelled',
          filter: 'Cancelled'
        }
      ]
    },
    {
      field: 'details',
      label: '',
      width: '5%'
    }
  ],

  pagination: {
    perPage: 12
  },

  rows: [],

  filters: {
    show: true
  },

  styles: {
    dateSelector: {
      display: 'flex',
      padding: '12px',
      textAlign: 'center',
      verticalAlign: 'middle',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px',
      background: 'none',
      border: 'none'
    }
  }
})
