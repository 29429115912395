import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'
import RowButton from '@/components/buttons/Row'
import { archReqs } from '@/services/Architectural/ArchitecturalSubmission/store'
import { statusTile } from '@/utilities/architectural/tile/status'
import { notifyError } from '@/services/notify'

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  openAdder() {
    this.$refs.adder.show({
      mode: 'manager'
    })
  },

  requestAdded() {
    this.getList()
  },

  determineRows: function({ list }) {
    var dateFormat = 'LL'

    var rows = list
      .map(entry => {
        var date = _get(entry, 'submissionDate', '')

        try {
          date = moment(date).format(dateFormat)
        } catch (exception) {
          console.error(exception)
        }

        const statusText = _get(entry, 'status', '')
        const { status } = statusTile({ statusText })

        const id = _get(entry, 'architecturalSubmissionID', '')

        return {
          requestID: id,
          name: _get(entry, 'name', ''),
          address: _get(entry, 'address', ''),
          category: _get(entry, 'architecturalCategoryName', ''),
          description: _get(entry, 'description', ''),
          date: {
            date,
            format: dateFormat
          },
          status,
          details: {
            component: RowButton,
            props: {
              to: {
                path: `architecturaltabs/${id}/architecturalRequestDetails#tab`,
                params: {
                  id
                }
              },
              text: 'Details'
            }
          },
          askaquestion: {
            component: RowButton,
            props: {
              to: {
                name: 'newconversation',
                params: {
                  architecturalsubmissionid: _get(entry, 'architecturalSubmissionID', 0),
                  archownerid: _get(entry, 'ownerID', 0),
                  archlabel: `Add Homeowner - ${_get(entry, 'ownerName', '')}`
                }
              },
              text: 'Ask a Question'
            }
          }
        }
      })
      .filter(row => {
        const rowDate = moment(row.date.date, dateFormat)

        if (this.toDate !== null) {
          const filterAfterDate = moment(this.toDate, dateFormat)

          if (rowDate.isAfter(filterAfterDate)) {
            return false
          }
        }

        if (this.fromDate !== null) {
          const filterBeforeDate = moment(this.fromDate, dateFormat)

          if (rowDate.isBefore(filterBeforeDate)) {
            return false
          }
        }

        return true
      })

    this.rows = rows
  },

  filterByDateRange: function() {
    const from = this.$refs.fromDateComponent.getValue()
    const to = this.$refs.toDateComponent.getValue()

    if (this.isDebug == true)
      console.debug('from = ' + JSON.stringify(from) + ', to=' + JSON.stringify(to))

    if (!from.year || !to.year) {
      notifyError('Please select a start and end date to filter by.')
      return
    } else {
      const fromYear = from.year
      const toYear = to.year

      if (fromYear.length > 0) {
        const year = fromYear
        const month = from.month || 'January'
        const dayOfMonth = from.day || '1'

        this.fromDate = `${month} ${dayOfMonth}, ${year}`
      }

      if (toYear.length > 0) {
        const year = toYear
        const month = to.month || 'December'

        const monthFormatted = moment()
          .month(month)
          .format('MM')

        // sets date as the last day of the month
        const dayOfMonth = to.day || moment(`${toYear}-${monthFormatted}`, 'YYYY-MM').daysInMonth()

        this.toDate = `${month} ${dayOfMonth}, ${year}`
      }
    }

    this.getList()
  },

  async getList() {
    this.loading = true

    const status = this.status
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { list } = await archReqs.dispatch('List', {
      params: {
        hoaID,
        approvedStatus: status == 'Approved' ? true : null,
        pendingStatus: status == 'Pending' ? true : null,
        deniedStatus: status == 'Denied' ? true : null,
        cancelledStatus: status == 'Cancelled' ? true : null,
        pendingNotVotedStatus: status == 'My Pending' ? true : null
      }
    })

    console.debug('mgmt arch request basic=' + JSON.stringify(list))

    this.determineRows({ list: _orderBy(list, 'submissionDate', 'desc') })

    this.loading = false
  },

  async updateTable({ filtersData }) {
    const status = filtersData.status

    if (status !== this.status) {
      this.status = status

      await this.getList()
    }
  }
}
