<template>
  <PageContentLayoutOnly>
    <ModernTable
      :columns="columns"
      :rows="rows"
      :pagination="pagination"
      :onUpdate="updateTable"
      :loading="loading"
      :filters="filters"
    >
      <template v-slot:header>
        <!-- 'title' attribute is used for testing -->
        <h3
          :style="{ display: 'inline-block' }"
          title
          role="main"
          :aria-label="$t('title')"
          tests-id="arch-requests-page"
        >
          {{ $t('title') }}
        </h3>

        <b-button
          type="is-ghost"
          @click="toggleFilters"
          :style="styles.filterButton"
          :class="'filter-button ' + (filters.show ? 'active' : '')"
        >
          <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
          </b-icon>
          <div style="display: inline-block">Filter</div>
        </b-button>

        <nav class="level">
          <div class="level-left">
            <ActionButton
              make-request
              type="is-primary"
              rounded
              @click="openAdder"
              :style="{ marginBottom: '20px' }"
              >Submit a New Request</ActionButton
            >
          </div>

          <!-- 'date-filter' attribute is used for testing -->
          <section
            v-if="filters.show"
            tabindex="0"
            aria-label="filter requests by date"
            :style="styles.dateSelector"
            class="date-selector"
            date-filter
          >
            <DatePicker
              ref="fromDateComponent"
              ariaLabel="filter from date"
              label="filter from date"
              ariaPrefix="from"
              :style="{ marginRight: '8px' }"
            />

            <DatePicker
              ref="toDateComponent"
              ariaLabel="to date"
              label="to date"
              ariaPrefix="to"
              :style="{ marginRight: '8px' }"
            />

            <b-button
              class="go-button"
              size="is-small"
              type="is-info"
              rounded
              @click="filterByDateRange"
              >Go</b-button
            >
          </section>
        </nav>
      </template>
    </ModernTable>

    <Adder ref="adder" :added="requestAdded" />
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import DatePicker from '@/components/inputs/DatePicker'
import ActionButton from '@/components/buttons/Action'
import { methods } from './keys/methods'
import { data } from './keys/data'
import Adder from '@/pages/Shared/architecturalRequest/Adder'

export default {
  name: 'Architectural',
  components: { PageContentLayoutOnly, ModernTable, DatePicker, Adder, ActionButton },
  isDebug: true,

  data,
  methods,

  async mounted() {
    this.getList()

    if (this.$route.params.filter && this.$route.params.filter != undefined) {
      if (this.isDebug == true) console.debug('filter=' + this.$route.params.filter)

      const filter =
        this.$route.params.filter &&
        this.$route.params.filter != undefined &&
        this.$route.params.filter.length > 0
          ? 'pending'
          : 'default'

      this.radio = filter
    }
  },

  watch: {
    radio: 'reloadRequests'
  },

  i18n: {
    messages: {
      en: {
        title: 'Architectural Requests'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

nav {
  margin: 0 !important;
}

@media screen and (max-width: $tablet) {
  .date-selector {
    justify-content: center !important;
  }
}

.go-button {
  margin-top: 14px;
}
</style>
